<template>
  <div class="system-container fixed_container">
    <a-menu v-model="current" mode="horizontal" class="menu_group fixed_menu" :defaultSelectedKeys="[$route.name]">
      <a-menu-item key="/financialManagementB/moneyb" v-if="allRoutes.includes('moneyb')">
        <router-link :to="{ name: 'moneyb' }">资金管理</router-link>
      </a-menu-item>
      <a-menu-item key="/financialManagementB/invoiceb" v-if="allRoutes.includes('invoiceb')">
        <router-link :to="{ name: 'invoiceb' }">发票管理</router-link>
      </a-menu-item>
      <a-menu-item key="/financialManagementB/advertisingTransfer" v-if="allRoutes.includes('advertisingTransfer')">
        <router-link :to="{ name: 'advertisingTransfer' }">广告主转账</router-link>
      </a-menu-item>
    </a-menu>
    <route-view></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
export default {
  name: 'FinancialManagementB',
  components: {
    RouteView
  },
  data () {
    return {
      current: ''
    }
  },
  computed: {
    ...mapState({
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes
    })
  },
  watch: {
    $route: {
      handler (val) {
        const routes = val.matched.concat()
        this.current = [routes.pop().path]
      },
      immediate: true
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
::v-deep.aggregate-container {
  background-color: #f7f7f7;
  .menu_group {
    margin-bottom: 10px;
  }
}
</style>
